import React from "react";
import ReactMarkdown from "react-markdown";

import "./MarkdownContentStyles.css";
import { useChatGpc } from "../hooks/backend/useChatGpc";
import { ChatMessage, ChatMessageAssistant } from "../types";

interface MarkdownContentProps {
  children: string;
  setChatHistory?: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
}

type CustomLinkProps = {
  href?: string;
  children: React.ReactNode;
  setChatHistory?: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
};

const CustomLink = ({
  children,
  setChatHistory,
  ...props
}: CustomLinkProps) => {
  const { confirmQuestion } = useChatGpc();
  const handleClick = async (event: any) => {
    event.preventDefault();

    if (props.href && props.href.endsWith("/confirm")) {
      const message_id = props.href.replace("/confirm", "").split("/").pop();
      // Perform any custom action here, such as navigating programmatically or logging analytics
      setChatHistory?.((state) => {
        return state.map((message) =>
          (message as ChatMessageAssistant)?.questionId === message_id
            ? { ...message, text: "refresh" }
            : message,
        );
      });

      const result = await confirmQuestion(props.href);

      setChatHistory?.((state) => {
        return state.map((message) =>
          (message as ChatMessageAssistant)?.questionId === message_id
            ? result
            : message,
        );
      });
    }
  };

  return (
    <a
      {...props}
      onClick={handleClick}
      style={{ cursor: "pointer", color: "blue" }}
    >
      {children}
    </a>
  );
};

const MarkdownContent = ({
  children,
  setChatHistory,
}: MarkdownContentProps) => (
  <ReactMarkdown
    components={{
      a: ({ ...props }) => (
        <CustomLink {...props} setChatHistory={setChatHistory} />
      ),
    }}
    className="message-formatted"
  >
    {children}
  </ReactMarkdown>
);

export default MarkdownContent;
