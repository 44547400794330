import chunk from "lodash/chunk";
import SkeletonTextBlock from "./SkeletonTextBlock";
import { memo, useEffect, useState } from "react";

import "./AssistantWelcomeMessageStyles.css";
import useMetricsDetails from "../hooks/backend/useMetricsDetails";

const AssistantWelcomeMessage = () => {
  const [metricNames, setMetricNames] = useState<string[]>([]);
  const { metricsDetails } = useMetricsDetails();

  useEffect(() => {
    if (metricsDetails) {
      setMetricNames(
        Object.keys(metricsDetails).map((metricName) =>
          metricName.replaceAll("_", " "),
        ),
      );
    }
  }, [setMetricNames, metricsDetails]);

  // Note: Join metrics array with ", " but the last one with " and ".
  /*
  const metricNamesEnlisted = metricNames?.length
    ? metricNames.slice(0, -1).join(", ") + " and " + metricNames.slice(-1)
    : "";
  */

  const metricColumns = chunk(metricNames, Math.ceil(metricNames.length / 3));

  const metricsEnlisted = metricColumns.map((metricCells, index) => (
    <td key={index}>
      <ul>
        {metricCells.map((metricName, index) => (
          <li key={metricName}>{metricName}</li>
        ))}
      </ul>
    </td>
  ));

  if (!metricsEnlisted.length) {
    return <SkeletonTextBlock />;
  }

  return (
    <div className="message-formatted welcome-message-bubble-wrapper">
      <p>
        {
          "Hello, I'm a chatbot assistant. I support you with data related to the following metrics:"
        }
      </p>
      <table className="metrics-table">
        <tbody>
          <tr>{metricsEnlisted}</tr>
        </tbody>
      </table>
      <p>{"Feel free to ask me anything about them."}</p>
      <p className="welcome-message-footer-text">
        {
          "For more information, please click the 'About' button located on the left panel."
        }
      </p>
    </div>
  );
};

export default memo(AssistantWelcomeMessage);
