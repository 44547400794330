import { useCallback } from "react";
import { useSetUserInfoContext } from "../contexts/UserInfoContext";

const useLogOut = () => {
  const setUserInfo = useSetUserInfoContext();
  const logout = useCallback(async () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const index = cookies[i].indexOf("=");
      const name = cookies[i].slice(0, index);
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    setUserInfo(null);
  }, [setUserInfo]);

  return logout;
};

export default useLogOut;
