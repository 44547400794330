import { useCallback } from "react";
import useGetDefaultRequestOptions from "../useGetDefaultRequestOptions";
import {
  useAppDetailsContext,
  useSetAppDetailsContext,
} from "../../contexts/AppDetailsContext";

const useInfo = () => {
  const appDetails = useAppDetailsContext();
  const setAppDetails = useSetAppDetailsContext();

  const getDefaultRequestOptions = useGetDefaultRequestOptions();

  const fetchInfo = useCallback(async () => {
    if (appDetails?.info) {
      return appDetails.info;
    }

    const INFO_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/v1/info`;

    try {
      const response = await fetch(INFO_ENDPOINT, getDefaultRequestOptions());

      if (response.status > 299) {
        throw new Error("Failed to fetch about info");
      }

      const responseJson = await response.json();

      // Adjust response as needed
      const replacedQuotes = responseJson.description.replaceAll("'", '"');
      const parsedInfo = JSON.parse(replacedQuotes);

      setAppDetails((state) => ({
        info: parsedInfo,
        ...state,
      }));

      return parsedInfo;
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
  }, [getDefaultRequestOptions, setAppDetails, appDetails.info]);

  return { info: appDetails.info, fetchInfo };
};

export default useInfo;
