import { Icon, TableHead } from "@bolt/components";
import { DatasetTableSchemaField } from "../types";
import { useCallback } from "react";

type DatasetTableHeaderCellProps = {
  setOrderDirection: React.Dispatch<React.SetStateAction<number>>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  setOrderBy: React.Dispatch<React.SetStateAction<number>>;
  tableHeader: DatasetTableSchemaField;
  index: number;
  orderDirection: number;
  orderBy: number;
  filterValue: string;
};

const FILTER_INPUT_CLASSNAME = "filter-input";

const DatasetTableHeaderCell = ({
  setOrderDirection,
  setFilters,
  setOrderBy,
  tableHeader,
  orderDirection,
  orderBy,
  index,
  filterValue,
}: DatasetTableHeaderCellProps) => {
  const handleOnClick = useCallback(
    (event: React.FormEvent<HTMLDivElement>) => {
      if (
        (event.target as HTMLDivElement)?.classList.contains(
          FILTER_INPUT_CLASSNAME,
        )
      ) {
        return;
      }

      setOrderBy(index);

      if (orderBy === index && orderDirection === 1) {
        setOrderDirection(-1);
      } else {
        setOrderDirection(1);
      }
    },
    [setOrderBy, setOrderDirection, orderBy, orderDirection, index],
  );

  const handleOnFilterChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setFilters((state) => ({
        ...state,
        [index]: (event.target as HTMLInputElement)?.value,
      }));
    },
    [setFilters, index],
  );

  return (
    <TableHead
      // Note: even if we display label we want to see the raw name on hover.
      title={tableHeader.name}
      className="table-header-cell"
      key={tableHeader.name + "-" + tableHeader.type}
      onClick={handleOnClick}
    >
      {tableHeader.label ? tableHeader.label : tableHeader.name}
      {index === orderBy && (
        <Icon
          className="table-header-cell-arrow"
          icon={orderDirection === 1 ? "arrow-down" : "arrow-up"}
        />
      )}
      {tableHeader.type !== "datetime" && tableHeader.type !== "date" && (
        <input
          type="text"
          className={FILTER_INPUT_CLASSNAME}
          placeholder="Type to filter..."
          value={filterValue}
          onChange={handleOnFilterChange}
        />
      )}
    </TableHead>
  );
};

export default DatasetTableHeaderCell;
