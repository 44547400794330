import { useMemo } from "react";
import Plot from "react-plotly.js";
import {
  ChatMessageAssistantParts,
  ChatMessageDataFrame,
  DataArray,
  DataRow,
} from "../types";
import groupBy from "lodash/groupBy";
import { Data, Layout } from "plotly.js";

import "./ChatAssistantResponseChartTabStyles.css";
import { ChatTheme } from "../hooks/useTheme";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";

import ReactMarkdown from "react-markdown";
import ChatAssistantResponseChartTabDetails from "./ChatAssistantResponseChartTabDetails";
import doesStringContainsDivisionAggregateName from "../utils/doesStringContainsDivisionAggregateName";
import getDataRowColumn from "../utils/getDataRowColumn";
import SelectColumnToVisualize from "./SelectColumnToVisualize";

type ChatAssistantResponseChartTabProps = {
  message: ChatMessageAssistantParts;
  theme: ChatTheme;
  columnToVisualizeIndex: number;
  setColumnToVisualizeIndex: React.Dispatch<React.SetStateAction<number>>;
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  detailsResult: ChatMessageDataFrame | undefined;
  setDetailsResult: React.Dispatch<
    React.SetStateAction<ChatMessageDataFrame | undefined>
  >;
};

const DARK_LAYOUT = {
  plot_bgcolor: "#03001f",
  paper_bgcolor: "#03001f",
  font: { color: "#DDD" },
};

const LIGHT_LAYOUT = {
  plot_bgcolor: "white",
  paper_bgcolor: "white",
  font: { color: "#03001f" },
};

const LAYOUT_BAR_CHART: Partial<Layout> = {
  showlegend: false,
  yaxis: {
    automargin: true,
  },
  margin: { l: 150 },
};

const LAYOUT_LINES_CHART = {
  showlegend: true,
};

export const CHART_WIDTH = 900;

function getDateColumnNameIndex(message: ChatMessageAssistantParts): number {
  const index = message.frame?.headers?.findIndex((field) =>
    field.name.includes("_DATE"),
  );
  if (typeof index === "undefined" || index === -1) {
    return 0;
  }
  return index;
}

function getIsBarChart(message: ChatMessageAssistantParts) {
  const axisXIndex = getDateColumnNameIndex(message);

  if (axisXIndex !== -1) {
    const dates = message.frame?.data.map((row) => row[axisXIndex]);
    if (uniq(dates).length === 1) {
      return true;
    }
  }

  return (
    JSON.stringify(message.details?.parameters?.date_to) ===
      JSON.stringify(message.details?.parameters?.date_from) &&
    message.details?.parameters?.date_to !== undefined
  );
}

const ChatAssistantResponseChartTab = ({
  message,
  columnToVisualizeIndex,
  setColumnToVisualizeIndex,
  theme,
  date,
  setDate,
  detailsResult,
  setDetailsResult,
}: ChatAssistantResponseChartTabProps) => {
  const metricName = message.details?.metric_name || "";
  const layoutTheme = theme === "dark" ? DARK_LAYOUT : LIGHT_LAYOUT;
  const isBarChart = getIsBarChart(message);
  const dcColumnIndex =
    message.frame?.headers.findIndex((field) =>
      field.name.toUpperCase().includes("DC_NAME"),
    ) ?? 0;
  const layoutChartTypeBased = isBarChart
    ? LAYOUT_BAR_CHART
    : LAYOUT_LINES_CHART;
  const dataRowColumn = getDataRowColumn(message, columnToVisualizeIndex);
  const dataRowColumnIndex =
    message.frame?.headers.findIndex((field) => field.name === dataRowColumn) ??
    0;
  const dataRowColumnToDisplay = dataRowColumn
    .replace("DC__", "")
    .replaceAll("_", " ");

  const summaryText = message.text;
  const dateColumnIndex = getDateColumnNameIndex(message);

  const displayColumnToVisualizeSelection =
    !!message.details?.columns_to_visualize &&
    message.details?.columns_to_visualize.length > 1;

  const displayThreshold =
    message.details?.parameters?.metric_threshold &&
    // Note: Display it only when the default column is chose.
    columnToVisualizeIndex === 0;

  function getDataBasedOnChartType(isBarChart: boolean): Data[] {
    if (isBarChart) {
      const dataSorted = sortBy(message.frame!.data as DataArray, [
        (row: DataRow) =>
          doesStringContainsDivisionAggregateName(String(row[dcColumnIndex])),
        dataRowColumnIndex,
      ]).reverse();

      const dataGrouped: Data = {
        x: dataSorted.map((dataRow) => dataRow[dataRowColumnIndex]),
        y: dataSorted.map((dataRow) => dataRow[dcColumnIndex]),
        type: "bar",
        orientation: "h",
        hoverinfo: "x+y",
      };

      return [
        dataGrouped,
        displayThreshold
          ? {
              y: message.frame?.data.map(
                (dataRow) => dataRow[dcColumnIndex] || dataRowColumnToDisplay,
              ),
              x: message.frame?.data.map(
                () => message?.details?.parameters?.metric_threshold!,
              ),
              name: "threshold",
              mode: "lines",
              line: { dash: "dash" },
              hoverinfo: "x+y",
            }
          : {},
      ];
    } else {
      const axisXIndex = dateColumnIndex;
      const dataGrouped = Object.values(
        groupBy(message.frame?.data || [], dcColumnIndex),
      ).map(
        (row): Data => ({
          x: row.map((dataRow) => new Date(dataRow[axisXIndex])),
          y: row.map((dataRow) => dataRow[dataRowColumnIndex]),
          type: "scatter",
          mode: "lines",
          name: String(row[0][dcColumnIndex]) || dataRowColumnToDisplay, // Default value - chart title
        }),
      );

      if (displayThreshold) {
        dataGrouped.push({
          x: message.frame?.data.map(
            (dataRow) => new Date(dataRow[axisXIndex]),
          ),
          y: message.frame?.data.map(
            () => message?.details?.parameters?.metric_threshold!,
          ),
          name: "threshold",
          mode: "lines",
          line: { dash: "dash" },
          hoverinfo: "x+y",
        });
      }

      return dataGrouped;
    }
  }

  const plotlyData = getDataBasedOnChartType(isBarChart);

  function getHeightBasedOnChartType(isBarChart: boolean): number {
    if (isBarChart) {
      const SINGLE_BAR_HEIGHT = 16;
      const BASE_HEIGHT_REQUIRED_TO_ALLOCATE_BAR_CHART_SAFELY = 178;

      // @ts-ignore: field exists
      if (plotlyData[0]?.y) {
        // @ts-ignore: field exists
        const countBars = uniq(plotlyData[0].y).length || 50;

        return (
          countBars * SINGLE_BAR_HEIGHT +
          BASE_HEIGHT_REQUIRED_TO_ALLOCATE_BAR_CHART_SAFELY
        );
      }

      return 900;
    }
    return 500;
  }

  // Note/TODO: it would be great to force plotly to update the colors once the theme change
  //            unfortunately it doesn't work by adding theme below to dependency array
  const plotlyDataVersion = useMemo(() => {
    return Math.floor(Math.random() * 99999999);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRowColumn]);

  const humanFriendlyColumnsToVisualize =
    message.details!.columns_to_visualize.map(
      (columnName) =>
        message.frame?.headers.find((header) => header.name === columnName)
          ?.label || columnName,
    );
  return (
    <>
      {/* If summary text is present - display it here in visualization tab */}
      {!!summaryText && <ReactMarkdown>{summaryText}</ReactMarkdown>}
      {displayColumnToVisualizeSelection && (
        <SelectColumnToVisualize
          columnToVisualizeIndex={columnToVisualizeIndex}
          setColumnToVisualizeIndex={setColumnToVisualizeIndex}
          columns={humanFriendlyColumnsToVisualize}
        />
      )}
      <div className="plotly-container">
        <Plot
          // Note: once we change it, the plotly will redraw the chart
          revision={plotlyDataVersion}
          data={plotlyData}
          layout={{
            ...(displayColumnToVisualizeSelection
              ? {}
              : { title: dataRowColumnToDisplay }),
            width: CHART_WIDTH,
            yaxis: { rangemode: "tozero" },
            ...layoutChartTypeBased,
            ...layoutTheme,
            height: getHeightBasedOnChartType(isBarChart),
          }}
        />
      </div>
      {metricName === "bill_of_lading_on_time_percentage" &&
        message.details?.parameters?.group_by_interval === "DAY" &&
        message.details?.parameters?.dc_name && (
          <ChatAssistantResponseChartTabDetails
            date={date}
            setDate={setDate}
            detailsResult={detailsResult}
            setDetailsResult={setDetailsResult}
            dateVariants={(message.frame?.data || []).map(
              (row) => new Date(row[dateColumnIndex]),
            )}
          />
        )}
    </>
  );
};

export default ChatAssistantResponseChartTab;
