import {
  ChatMessage,
  ChatMessageAssistant,
  ChatMessageDataFrame,
} from "../types";
import ChatGPCLogomark from "./ChatGPCLogomark";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@bolt/components";

import "./ChatAssistantResponseMessageStyles.css";
import ChatAssistantResponseChartTab from "./ChatAssistantResponseChartTab";
import ChatAssistantResponseDetailsTab from "./ChatAssistantResponseDetailsTab";
import DatasetTable from "./DatasetTable";
import { memo, useState } from "react";
import { ChatTheme } from "../hooks/useTheme";
import MarkdownContent from "./MarkdownContent";

type ChatAssistantResponseMessageProps = {
  message: ChatMessageAssistant;
  theme: ChatTheme;
  setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
};

const ChatAssistantResponseMessage = ({
  message,
  theme,
  setChatHistory,
}: ChatAssistantResponseMessageProps) => {
  const [columnToVisualizeIndex, setColumnToVisualizeIndex] = useState(0);
  const [date, setDate] = useState<Date | null>(null);
  const [detailsResult, setDetailsResult] = useState<ChatMessageDataFrame>();
  const showVisualizationAndDataSourceTab = !!message.frame?.data;
  /* Note:
    SPEC-1931
    Show summary only when there is no Visualization tab.
    Otherwise show Summary above the chart at Visualization tab.
  */
  const showSummaryTab = !showVisualizationAndDataSourceTab && message.text;
  const showDetailsTab =
    message.details?.metric_name && message.details.metric_name !== "about";

  const defaultActiveTab = showVisualizationAndDataSourceTab
    ? "visualization"
    : "summary";

  return (
    <div className="message bot-message">
      <ChatGPCLogomark className="chat-img" />
      <Tabs
        defaultValue={defaultActiveTab}
        variant="with-rule-ghost"
        className="answer-tabs"
      >
        <TabsList>
          {showSummaryTab && <TabsTrigger value="summary">Summary</TabsTrigger>}

          {showVisualizationAndDataSourceTab && (
            <TabsTrigger value="visualization">Visualization</TabsTrigger>
          )}

          {showVisualizationAndDataSourceTab && (
            <TabsTrigger value="data">Data Source</TabsTrigger>
          )}

          {showDetailsTab && <TabsTrigger value="details">Details</TabsTrigger>}
          {false && <TabsTrigger value="reasoning">Reasoning</TabsTrigger>}
        </TabsList>

        {showSummaryTab && (
          <TabsContent value="summary">
            <MarkdownContent setChatHistory={setChatHistory}>
              {message.text!}
            </MarkdownContent>
          </TabsContent>
        )}

        {showVisualizationAndDataSourceTab && (
          <TabsContent value="visualization" className="overflow-x-auto">
            <ChatAssistantResponseChartTab
              columnToVisualizeIndex={columnToVisualizeIndex}
              setColumnToVisualizeIndex={setColumnToVisualizeIndex}
              date={date}
              setDate={setDate}
              detailsResult={detailsResult}
              setDetailsResult={setDetailsResult}
              message={message}
              theme={theme}
            />
          </TabsContent>
        )}

        {showVisualizationAndDataSourceTab && (
          <TabsContent value="data" className="overflow-x-auto">
            <DatasetTable
              data={message.frame?.data!}
              headers={message.frame?.headers!}
            />
          </TabsContent>
        )}

        {showDetailsTab && (
          <TabsContent value="details">
            <ChatAssistantResponseDetailsTab details={message.details!} />
          </TabsContent>
        )}
      </Tabs>
    </div>
  );
};

export default memo(
  ChatAssistantResponseMessage,
  (prev, next) => prev.message.questionId === next.message.questionId,
);
