import { useCallback } from "react";
import { useUserInfoContext } from "../contexts/UserInfoContext";
import { FETCH_USER_AGENT } from "../config";

type useGetRequestOptionsReturnType = (
  override?: RequestInit,

  // Note: This argument is for passing accessToken, when we want to use new accessToken immediately
  // (without waiting for userInfo state to be updated)
  accessToken?: string,
) => RequestInit;

const useGetDefaultRequestOptions = (): useGetRequestOptionsReturnType => {
  const userInfo = useUserInfoContext();

  const getRequestOptions = useCallback(
    (override: RequestInit = {}, accessToken?: string) => ({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...(accessToken
          ? { Authorization: `Bearer ${accessToken}` }
          : userInfo?.accessToken
            ? { Authorization: `Bearer ${userInfo?.accessToken}` }
            : {}),
        "User-Agent": FETCH_USER_AGENT,
      },
      mode: "cors" as RequestMode,
      ...override,
    }),
    [userInfo],
  );

  return getRequestOptions;
};

export default useGetDefaultRequestOptions;
