import { useCallback } from "react";
import useGetDefaultRequestOptions from "../useGetDefaultRequestOptions";
import {
  useAppDetailsContext,
  useSetAppDetailsContext,
} from "../../contexts/AppDetailsContext";

const useMetricsDetails = () => {
  const getDefaultRequestOptions = useGetDefaultRequestOptions();

  const appDetails = useAppDetailsContext();
  const setAppDetails = useSetAppDetailsContext();

  const fetchMetricsDetails = useCallback(async () => {
    if (appDetails.metricsDetails) {
      return appDetails.metricsDetails;
    }

    const DETAILS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/v1/info/metrics`;

    try {
      const response = await fetch(
        DETAILS_ENDPOINT,
        getDefaultRequestOptions(),
      );
      if (response.status > 299) {
        throw new Error("Failed to fetch details");
      }
      const data = await response.json();
      setAppDetails((state) => ({ metricsDetails: data, ...state }));

      return data;
    } catch (error: any) {
      console.error(error.message);
    }
  }, [appDetails, getDefaultRequestOptions, setAppDetails]);

  const getMetricsDetails = useCallback(
    (key: string) => {
      return appDetails.metricsDetails?.[key]?.description;
    },
    [appDetails],
  );
  return {
    getMetricsDetails,
    fetchMetricsDetails,
    metricsDetails: appDetails.metricsDetails,
  };
};

export default useMetricsDetails;
