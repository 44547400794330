import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@bolt/components";
import { ChatMessageAssistantPartsDetails } from "../types";
import without from "lodash/without";

import "./ChatAssistantresponseDetailsTabStyles.css";
import { LOCALE_APP } from "../config";
import useMetricsDetails from "../hooks/backend/useMetricsDetails";

type ChatAssistantResponseDetailsTabProps = {
  details: ChatMessageAssistantPartsDetails;
};

const ChatAssistantResponseDetailsTab = ({
  details,
}: ChatAssistantResponseDetailsTabProps) => {
  const { getMetricsDetails } = useMetricsDetails();
  const date_from = new Date();
  date_from.setFullYear(parseInt(details.parameters.date_from?.year), parseInt(details.parameters.date_from?.month) - 1, parseInt(details.parameters.date_from?.day));

  const date_to = new Date();
  date_to.setFullYear(parseInt(details.parameters.date_to?.year), parseInt(details.parameters.date_to?.month) - 1, parseInt(details.parameters.date_to?.day));

  const detailsText = getMetricsDetails(details.metric_name);

  const restParameters = without(
    Object.keys(details.parameters),
    "date_from",
    "date_to",
  );
  const [metricDescription, formula] = detailsText?.includes(" Formula: ")
    ? detailsText!.split(" Formula: ")
    : [detailsText || "", ""];
  return (
    <>
      {metricDescription && (
        <>
          <p>
            <strong>Metric description</strong>
          </p>
          <p>{metricDescription}</p>
        </>
      )}
      {formula && (
        <>
          <p>
            <strong>Formula</strong>
          </p>
          <p>{formula}</p>
        </>
      )}

      {/* TODO: it might be customized to print everything inside of the object */}
      <Table density="default" variant="line" className="table-parameters">
        <TableHeader>
          <TableRow>
            <TableHead>parameter</TableHead>
            <TableHead>value</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {details.metric_name && (
            <TableRow>
              <TableHead>metric_name</TableHead>
              <TableCell>{details.metric_name}</TableCell>
            </TableRow>
          )}
          {details.parameters.date_from && (
            <TableRow>
              <TableHead>date_from</TableHead>
              <TableCell>{date_from.toLocaleDateString(LOCALE_APP)}</TableCell>
            </TableRow>
          )}
          {details.parameters.date_to && (
            <TableRow>
              <TableHead>date_to</TableHead>
              <TableCell>{date_to.toLocaleDateString(LOCALE_APP)}</TableCell>
            </TableRow>
          )}
          {restParameters.map((parameterKey) => (
            <TableRow>
              <TableHead>{parameterKey}</TableHead>
              <TableCell>{details.parameters[parameterKey]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ChatAssistantResponseDetailsTab;
