import Cookies from "js-cookie";
import {
  COOKIES_EXPIRATION_DELAY,
  COOKIE_NAME_REFRESH_TOKEN,
} from "../config/cookies";

export default function setRefreshTokenToCookies(refreshToken: string) {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + COOKIES_EXPIRATION_DELAY);

  Cookies.set(COOKIE_NAME_REFRESH_TOKEN, refreshToken, {
    expires: expirationDate,
    secure: true,
  });
}
