import React, { Dispatch, SetStateAction } from "react";
import "./termsAndConditionsStyles.css";

import { display } from "../../../utils/displayValues";
import useTermsAndConditions from "../../../hooks/backend/useTermsAndConditions";
import ChatGPCLogomark from "../../ChatGPCLogomark";
import GuidanceInfoContent from "../Info/GuidanceInfoContent";
import { useSetUserInfoContext } from "../../../contexts/UserInfoContext";

type TermsAndConditionsProps = {
  setTermsAccepted: Dispatch<SetStateAction<boolean>>;
  terms: string | null;
};

const TermsAndConditions = ({
  setTermsAccepted,
  terms,
}: TermsAndConditionsProps) => {
  const setUserInfo = useSetUserInfoContext();

  const { acceptTerms } = useTermsAndConditions();

  const handleAccept = async () => {
    setTermsAccepted(true);
    await acceptTerms();
  };

  // TODO: It's just log out. A hook might be created for logging out and used here.
  const handleDecline = async () => {
    setUserInfo(null);
  };

  return (
    <div>
      <div className="terms-header-bar">
        <ChatGPCLogomark className="gpc-logo-terms" />
      </div>
      <div className="terms-content">
        <div className="header-text">GENERATIVE AI GUIDANCE</div>
        <div className="terms-body">
          <GuidanceInfoContent terms={terms} />
        </div>
        <div className="red-text">
          By clicking “Accept”, I attest that I have read the GPC Employee
          Generative AI Guidance document and will abide by the policies and
          procedures set in place by GPC. I understand that failure to follow
          these policies and procedures may result in disciplinary actions up to
          and including termination.
        </div>
        <div className="button-group">
          <button onClick={handleDecline} className="decline-button">
            {display["en"].decline}
          </button>
          <button
            data-testid="accept-terms"
            className="accept-button"
            onClick={handleAccept}
          >
            {display["en"].accept}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
