const DIVISION_AGGREGATES = [
  "MIDWEST DIVISION",
  "APG",
  "CENTRAL DIVISION",
  "WEST DIVISION",
  "NORTHEAST DIVISION",
  "UPSTREAM DIVISION",
];

export default function doesStringContainsDivisionAggregateName(str: string) {
  return !DIVISION_AGGREGATES.some((divisionAggregateName: string) =>
    str.toUpperCase().includes(divisionAggregateName),
  );
}
