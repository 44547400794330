import { TableCell } from "@bolt/components";
import { memo } from "react";
import { LOCALE_APP } from "../config";
import { DataCell } from "../types";

type DatasetTableCellProps = {
  value: DataCell;
  type: string;
};

const DatasetTableCell = ({ value, type }: DatasetTableCellProps) => {
  if (type === "datetime") {
    return (
      <TableCell title={value as string}>
        {new Date(value).toLocaleString(LOCALE_APP)}
      </TableCell>
    );
  } else if (type === "date") {
    return (
      <TableCell title={value as string}>
        {new Date(value).toLocaleDateString(LOCALE_APP)}
      </TableCell>
    );
  }

  return <TableCell>{value}</TableCell>;
};

export default memo(DatasetTableCell);
