import { Table, TableBody, TableHeader, TableRow } from "@bolt/components";
import { DataArray, DataCell, DatasetTableSchemaFields } from "../types";
import DownloadCSVButton from "./DownloadCSVButton";
import { memo, useEffect, useState } from "react";

import "./DatasetTableStyles.css";
import DatasetTableCell from "./DatasetTableCell";
import TablePagination, { PaginationState } from "./TablePagination";
import DatasetTableHeaderCell from "./DatasetTableHeaderCell";

type DatasetTableProps = {
  headers: DatasetTableSchemaFields;
  data: DataArray;
  showDownloadCSVButton?: boolean;
};

const CSV_FILENAME = "CSV_ChatWithData-data";

function mapPropsToCSVData(
  headers: DatasetTableSchemaFields,
  data: DataArray,
): DataArray {
  const CSVdata: DataArray = [headers.map((field) => field.label)];

  data.forEach((dataRow) => {
    CSVdata.push(dataRow);
  });

  return CSVdata;
}

const DATA_ROWS_PER_PAGE = 20;

const DatasetTable = ({
  headers,
  data,
  showDownloadCSVButton = true,
}: DatasetTableProps) => {
  const tableHeaders = headers || [];
  const [filters, setFilters] = useState<Record<number, string>>({});

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DATA_ROWS_PER_PAGE,
  });

  const [orderBy, setOrderBy] = useState(0);
  const [orderDirection, setOrderDirection] = useState(1);

  const tableFiltered = [...data].filter((row) =>
    row.every((cellValue, index) =>
      String(cellValue)
        .toUpperCase()
        .includes((filters[index] || "").toUpperCase()),
    ),
  );

  const tableDataSorted = tableFiltered.sort((dataRow1, dataRow2) =>
    dataRow1[orderBy] < dataRow2[orderBy]
      ? -1 * orderDirection
      : 1 * orderDirection,
  );

  const tableDataSortedPaginated = tableDataSorted.slice(
    pagination.pageIndex * pagination.pageSize,
    (pagination.pageIndex + 1) * pagination.pageSize,
  );

  useEffect(() => {
    setPagination((pagination) => ({
      ...pagination,
      pageIndex: 0,
    }));
  }, [filters]);

  const dataCSV = mapPropsToCSVData(headers, data);

  return (
    <>
      {showDownloadCSVButton && (
        <div className="btn-wrapper-csv">
          <DownloadCSVButton
            fileName={CSV_FILENAME}
            data={dataCSV}
            className="btn-download-csv"
          />
        </div>
      )}
      <div>
        <div className="dataset-table-wrapper">
          <Table density="default" variant="line" className="table-dataset">
            <TableHeader>
              <TableRow>
                {tableHeaders.map((tableHeader, index) => (
                  <DatasetTableHeaderCell
                    index={index}
                    key={tableHeader.name}
                    filterValue={filters[index] || ""}
                    setOrderBy={setOrderBy}
                    setFilters={setFilters}
                    setOrderDirection={setOrderDirection}
                    orderDirection={orderDirection}
                    tableHeader={tableHeader}
                    orderBy={orderBy}
                  />
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {tableDataSortedPaginated.map((dataRow, index) => (
                <TableRow key={index}>
                  {dataRow.map((value: DataCell, indexCell: number) => (
                    <DatasetTableCell
                      key={indexCell}
                      value={value}
                      type={tableHeaders[indexCell].type}
                    />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          pagination={pagination}
          setPagination={setPagination}
          dataRows={tableDataSorted}
        />
      </div>
    </>
  );
};

export default memo(DatasetTable);
