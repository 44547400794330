import { useCallback } from "react";
import useAuth from "./backend/useAuth";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "../contexts/UserInfoContext";
import useLogOut from "./useLogOut";
import setRefreshTokenToCookies from "../utils/setRefreshTokenToCookies";

const useExpirationGuard = () => {
  const setUserInfo = useSetUserInfoContext();
  const userInfo = useUserInfoContext()!;
  const logout = useLogOut();
  const { refreshToken } = useAuth();

  const expirationGuard = useCallback(
    async (): Promise<string> /* access token */ => {
      if (userInfo.expiresAt < Date.now()) {
        // TODO: the exceptions should be handled at refreshToken fetch
        try {
          const refreshResult = await refreshToken();

          setRefreshTokenToCookies(refreshResult.refreshToken);
          setUserInfo(refreshResult);

          return refreshResult.accessToken;
        } catch (e: any) {
          logout();

          console.warn("Unable to refresh the token", e);
        }
      }

      return userInfo.accessToken;
    },
    [userInfo, setUserInfo, refreshToken, logout],
  );

  return expirationGuard;
};

export default useExpirationGuard;
