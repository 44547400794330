import { useEffect } from "react";
import useMetricsDetails from "./useMetricsDetails";
import { useUserInfoContext } from "../../contexts/UserInfoContext";
import useInfo from "./useInfo";
import {
  useAppDetailsContext,
  useSetAppDetailsContext,
} from "../../contexts/AppDetailsContext";

const useFetchInitialData = () => {
  const { isLoading = false } = useAppDetailsContext();
  const setAppDetails = useSetAppDetailsContext();

  const { fetchMetricsDetails, metricsDetails } = useMetricsDetails();
  const { fetchInfo, info } = useInfo();

  const userInfo = useUserInfoContext();

  useEffect(() => {
    if (userInfo?.accessToken && !info && !metricsDetails && !isLoading) {
      setAppDetails((state) => ({ isLoading: true, ...state }));

      Promise.all([fetchMetricsDetails(), fetchInfo()]).finally(() => {
        setAppDetails((state) => ({ isLoading: false, ...state }));
      });
    }
  }, [
    userInfo?.accessToken,
    info,
    isLoading,
    fetchInfo,
    fetchMetricsDetails,
    metricsDetails,
    setAppDetails,
  ]);
};

export default useFetchInitialData;
