import React, { useEffect, useState } from "react";
import ChatUi from "./components/pages/ChatUi/ChatUi";
import LandingPage from "./components/pages/LandingPage/LandingPage";
import TermsAndConditions from "./components/pages/TermsAndConditions/TermsAndConditions";
import LoadingPage from "./components/pages/LoadingPage/LoadingPage";
import Cookies from "js-cookie";
import { COOKIE_NAME_REFRESH_TOKEN } from "./config/cookies";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "./contexts/UserInfoContext";
import useAuth from "./hooks/backend/useAuth";
import useTermsAndConditions from "./hooks/backend/useTermsAndConditions";
import useExpirationGuard from "./hooks/useExpirationGuard";
import setRefreshTokenToCookies from "./utils/setRefreshTokenToCookies";

function App() {
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(true);
  const [terms, setTerms] = useState<string | null>(null);
  const { getAccessToken } = useAuth();
  const userInfo = useUserInfoContext();
  const setUserInfo = useSetUserInfoContext();
  const { getTerms } = useTermsAndConditions();
  const expirationGuard = useExpirationGuard();

  useEffect(() => {
    const codeMatch = window.location.href.match("[?#&]code=([^&]*)");
    const errorMatch = window.location.href.match(
      "User%20does%20not%20have%20appropriate%20group%20memberships",
    );

    if (errorMatch) {
      alert("user does not have access to the appropriate group");
      window.history.replaceState({}, "", window.location.pathname);
    } else if (codeMatch?.[1]) {
      setLoading(true);

      const authorize = async () => {
        window.history.replaceState({}, "", window.location.pathname);

        const pingIdJson = await getAccessToken(codeMatch[1]);
        setRefreshTokenToCookies(pingIdJson.refreshToken);

        setUserInfo(pingIdJson);
      };

      authorize().catch(console.error);
    } else {
      const refresh_token = Cookies.get(COOKIE_NAME_REFRESH_TOKEN);

      if (
        refresh_token &&
        refresh_token !== "undefined" &&
        refresh_token !== userInfo?.refreshToken
      ) {
        // so this is a little confusing. Instead of refreshing the token when the page is refreshed we are
        // just updating the user object, that way we don't have to worry about the state of tokens being
        // controlled by multiple tabs, the state of one window won't conflict with other opened sessions,
        // and it will save on initial load time
        setUserInfo({
          accessToken: "",
          refreshToken: refresh_token,
          expiresAt: 0,
          idToken: "",
        });
      }
    }
  }, [
    setUserInfo,
    setLoading,
    getAccessToken,
    getTerms,
    userInfo?.refreshToken,
  ]);

  // Note: If we don't have accessToken - get one
  useEffect(() => {
    if (userInfo?.refreshToken && !userInfo?.accessToken) {
      expirationGuard();
    }
  }, [userInfo?.refreshToken, userInfo?.accessToken, expirationGuard]);

  useEffect(() => {
    if (!userInfo?.accessToken || !!terms || !loading) {
      return;
    }

    getTerms()
      .then((result) => {
        setTermsAccepted(result.accepted);
        setTerms(result.terms);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [userInfo, getTerms, terms, loading]);

  if (loading) {
    return <LoadingPage />;
  }

  if (!userInfo) {
    return <LandingPage />;
  }

  if (!termsAccepted) {
    return (
      <TermsAndConditions setTermsAccepted={setTermsAccepted} terms={terms} />
    );
  }

  return <ChatUi />;
}

export default App;
