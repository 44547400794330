import React from "react";
import ReactDOM from "react-dom/client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "@bolt/foundations/theme";
import "@bolt/components/styles";
import UserInfoContextWrapper from "./contexts/UserInfoContext";
import ChatDetailsContextWrapper from "./contexts/ChatContext";
import AppDetailsContextWrapper from "./contexts/AppDetailsContext";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserInfoContextWrapper>
        <ChatDetailsContextWrapper>
          <AppDetailsContextWrapper>
            <App />
          </AppDetailsContextWrapper>
        </ChatDetailsContextWrapper>
      </UserInfoContextWrapper>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
