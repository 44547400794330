import { ChatMessageAssistantParts } from "../types";

export default function getDataRowColumn(
  message: ChatMessageAssistantParts,
  columnToVisualizeIndex: number = 0,
) {
  if (message.details?.columns_to_visualize?.[columnToVisualizeIndex]) {
    return message.details?.columns_to_visualize[columnToVisualizeIndex];
  }

  // Note: The code below is probably not in use because columns_to_visualize should be always returned by backend
  return (
    message.frame?.headers.find((field) =>
      field.name
        .toUpperCase()
        .includes((message.details?.metric_name || "").toUpperCase()),
    )?.name ||
    message.frame?.headers.find(
      (field) =>
        field.type === "number" && field.name.toUpperCase() !== "DIFFERENCE",
    )?.name ||
    ""
  );
}
