import { useState, useCallback } from "react";
import useGetDefaultRequestOptions from "../useGetDefaultRequestOptions";

type TermsResponse = {
  accepted: boolean;
  terms: string | null;
};

const useTermsAndConditions = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const getDefaultRequestOptions = useGetDefaultRequestOptions();

  const getTerms = useCallback(async (): Promise<TermsResponse> => {
    const TERMS_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v1/terms";

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(TERMS_ENDPOINT, getDefaultRequestOptions());
      const data = await response.json();
      return data;
    } catch (error) {
      setError("Failed to fetch terms and conditions.");
      return {
        accepted: false,
        terms: "Unable to validate terms and conditions acceptance",
      };
    } finally {
      setLoading(false);
    }
  }, [getDefaultRequestOptions]);

  const acceptTerms = useCallback(async () => {
    const TERMS_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v1/terms";

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        TERMS_ENDPOINT,
        getDefaultRequestOptions({
          method: "POST",
          body: JSON.stringify({
            accepted: true,
          }),
        }),
      );
      await response.json();
    } catch (error) {
      setError("Failed to accept terms and conditions.");
      console.error("oops did not work", error);
    } finally {
      setLoading(false);
    }
  }, [getDefaultRequestOptions]);

  return {
    loading,
    error,
    getTerms,
    acceptTerms,
  };
};

export default useTermsAndConditions;
