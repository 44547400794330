import { useCallback } from "react";
import { AuthResponse, UserInfo } from "../../types";
import {
  useSetUserInfoContext,
  useUserInfoContext,
} from "../../contexts/UserInfoContext";
import useGetDefaultRequestOptions from "../useGetDefaultRequestOptions";
import useLogOut from "../useLogOut";

function mapAuthResponseToUserInfo(response: AuthResponse): UserInfo {
  return {
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
    expiresAt: response.expires_in * 1000 + Date.now(),
    idToken: response.id_token,
  };
}

const useAuth = () => {
  const setUserInfo = useSetUserInfoContext();
  const userInfo = useUserInfoContext();
  const getDefaultRequestOptions = useGetDefaultRequestOptions();
  const logout = useLogOut();

  const getAccessToken = useCallback(
    async (code: string) => {
      try {
        const apiResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/token?code=${code}&redirect_uri=${process.env.REACT_APP_BASE_URL}`,
          getDefaultRequestOptions(),
        );

        if (!apiResponse.ok) {
          throw new Error("Failed to fetch access token");
        }

        const response: AuthResponse = await apiResponse.json();

        const userInfoPayload = mapAuthResponseToUserInfo(response);

        setUserInfo(userInfoPayload);

        return userInfoPayload;
      } catch (error) {
        console.error("Error fetching access token:", error);
        throw error;
      }
    },
    [setUserInfo, getDefaultRequestOptions],
  );

  const refreshToken = useCallback(async () => {
    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/refresh`,
        getDefaultRequestOptions({
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userInfo?.refreshToken}`,
          },
        }),
      );

      if (!apiResponse.ok) {
        throw new Error("Failed to refresh token");
      }

      const response: AuthResponse = await apiResponse.json();

      const userInfoPayload = mapAuthResponseToUserInfo(response);

      setUserInfo(userInfoPayload);

      return userInfoPayload;
    } catch (error) {
      logout();

      console.warn("Error refreshing token:", error);
      throw error;
    }
  }, [setUserInfo, userInfo, getDefaultRequestOptions, logout]);

  return { getAccessToken, refreshToken };
};

export default useAuth;
