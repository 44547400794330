import React, { memo } from "react";

import "./ExampleQuestionTilesStyles.css";
import MarkdownContent from "./MarkdownContent";

// Note: example questions taken from this JIRA ticket:
// https://gpcprod.atlassian.net/browse/SPEC-1988
const EXAMPLE_QUESTIONS = [
  "Give me **BOL** % for Atlanta in March of 2024.",
  "Give me **Freight Inventory Amount** from 10th of May 2024 to 24th of May 2024 daily for Altoona DC",
  "What was **Freight Putaway Open Load Count** in Washington DC on 10 September 2024?",
  "Show me **Freight Putaway Oldest Open Load Count** in Central Division in September 2024?",
  "Show me **Factory Returns Oldest Released Return Count** in Midwest Division in August 2024?",
  "Give me **Factory Returns Released Return Count** for Boston DC on September 25",
  "What was the **Inventory Accuracy** for 22 May 2023 in Boston compared to the target of 97.5%?",
  "Give me daily **UPH** for November 2023 in Central Division.",
  "What was **Cycle Count Variance** on 15 July 2024 at Boston DC?",
  "What was **Lines Count** on 15 July 2024 at Boston DC?",
  "What was the **OT** from 9 to 13 September 2024 at Atlanta DC?",
  "What is the **Inventory Accuracy** for Los Angeles in May 2023? Goal: 95%.",
];

type ExampleQuestionTilesProps = {
  onClick: (prompt: string) => void;
};

const ExampleQuestionTiles = ({ onClick }: ExampleQuestionTilesProps) => {
  return (
    <div className="container-example-questions">
      {EXAMPLE_QUESTIONS.map((exampleQuestion) => (
        <button
          key={exampleQuestion}
          className="tile-example-question"
          onClick={() =>
            onClick(
              // Note: remove replace MarkDown highlights
              exampleQuestion.replaceAll("*", ""),
            )
          }
        >
          <MarkdownContent>{exampleQuestion}</MarkdownContent>
        </button>
      ))}
    </div>
  );
};

export default memo(ExampleQuestionTiles);
