import { PropsWithChildren, createContext, useContext, useState } from "react";
import { ChatMessageAssistantParts, MetricsDetails } from "../types";

type AppDetails = {
  isLoading?: boolean;
  info?: ChatMessageAssistantParts;
  metricsDetails?: MetricsDetails;
};

export const AppDetailsContext = createContext<AppDetails>({});

export const SetAppDetailsContext = createContext<
  React.Dispatch<React.SetStateAction<AppDetails>>
>(() => {});

export const useAppDetailsContext = () => useContext(AppDetailsContext);

export const useSetAppDetailsContext = () => useContext(SetAppDetailsContext);

const AppDetailsContextWrapper = ({ children }: PropsWithChildren) => {
  const [appDetails, setAppDetails] = useState<AppDetails>({});

  return (
    <AppDetailsContext.Provider value={appDetails}>
      <SetAppDetailsContext.Provider value={setAppDetails}>
        {children}
      </SetAppDetailsContext.Provider>
    </AppDetailsContext.Provider>
  );
};

export default AppDetailsContextWrapper;
