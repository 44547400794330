import { PropsWithChildren, createContext, useContext, useState } from "react";
import { ChatDetails } from "../types";

export const ChatDetailsContext = createContext<ChatDetails | null>(null);

export const SetChatDetailsContext = createContext<
  React.Dispatch<React.SetStateAction<ChatDetails | null>>
>(() => {});

export const useChatDetailsContext = () => useContext(ChatDetailsContext);

export const useSetChatDetailsContext = () => useContext(SetChatDetailsContext);

const ChatDetailsContextWrapper = ({ children }: PropsWithChildren) => {
  const [ChatDetails, setChatDetails] = useState<null | ChatDetails>(null);

  return (
    <ChatDetailsContext.Provider value={ChatDetails}>
      <SetChatDetailsContext.Provider value={setChatDetails}>
        {children}
      </SetChatDetailsContext.Provider>
    </ChatDetailsContext.Provider>
  );
};

export default ChatDetailsContextWrapper;
