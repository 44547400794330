import { Button } from "@bolt/components";
import { memo } from "react";

import useLogOut from "../hooks/useLogOut";

const LogOutButton = () => {
  const handleLogout = useLogOut();

  return (
    <Button
      variant="outline"
      size="lg"
      data-testid="logout-button"
      className="logout-button"
      onClick={handleLogout}
      iconLeft="right-from-bracket"
      title="Log out"
    >
      <span className="hide-on-mobile">Log out</span>
    </Button>
  );
};

export default memo(LogOutButton);
